.top-bar {
  width: 100%;
  background-color: var(--primary-color);
  height: 40px;
  padding: 10px;
  z-index: 10;
  transition: all 0.2s ease-in-out;
}
.top-cont {
  display: flex;
}
.top-cont > p {
  padding: 0 10px;
  font-size: 12px;
  color: white;
}

@media screen and (max-width: 600px) {
  .top-cont > p {
    font-size: 10px;
  }
}
