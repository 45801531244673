:root {
  --primary-color: #047484;
  --secondary-color: #252525;
  --third-color: #fff;
}
.instructors {
  padding: 120px 0;
  position: relative;
  overflow: hidden;
}
.bgg {
  position: absolute;
  width: 400px;
  height: 100%;
  background-image: url("../../assets/bgg2.png");
  background-repeat: no-repeat;
  background-size: 100%;
  opacity: 0.5;
  left: 0;
  top: 30%;
  z-index: -1;
}
.bgg2 {
  position: absolute;
  width: 300px;
  height: 100%;
  background-image: url("../../assets/bgg3.png");
  background-repeat: no-repeat;
  background-size: 100%;
  opacity: 0.5;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.bgg3 {
  position: absolute;
  width: 500px;
  height: 100%;
  background-image: url("../../assets/bgg4.png");
  background-repeat: no-repeat;
  background-size: 100%;
  opacity: 0.1;
  left: 50%;
  bottom: 0;
  z-index: -1;
  transform: rotate(180deg);
}
.ins_card_1 {
  overflow: hidden;
  border-color: #d2dfe9;
  box-shadow: 0 2px 5px #eee;
  border-radius: 10px;
  margin: 10px;
}
.ins_card_1 > .card-img-top {
  /* height: 300px; */
  overflow: hidden;
}
.ins_card_1 .card-body > h2 {
  font-size: 20px;
  user-select: none;
  transition: all 0.2s ease-in-out;
}
.ins_card_1:hover .card-body > h2 {
  color: var(--third-color);
}
.ins_card_1 .card-img-top > img {
  max-width: 100%;
  transition: all 1s ease-in-out;
}
.ins_card_1:hover .card-img-top > img {
  transform: scale(1.2);
}
.social-2 {
  position: relative;
  background-color: #eee;
  width: 100%;
  border-radius: 10px;
}
.social-2 a {
  color: var(--secondary-color);
  padding: 10px;
  margin: 0 auto;
  transition: all 0.2s ease-in-out;
}
.social-2 .fb:hover {
  color: #4267b2;
}
.social-2 .twt:hover {
  color: #00acee;
}
.social-2 .linkin:hover {
  color: #0077b5;
}
.social-2 .insta:hover {
  color: #8a3ab9;
}
.social-2 .yt:hover {
  color: #ff0000;
}


@media screen and (max-width:786px) {
    .bgg,.bgg2.bgg3{
        display: none;
    }
}