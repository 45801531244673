.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}
.pop-inner {
  position: absolute;
  top: 10%;
  padding: 20px;
  background-color: #fff;
  overflow: hidden;
  margin: 10px;
  -webkit-animation: fadeinout 1s linear forwards;
  animation: fadeinout 1s linear forwards;
}

@keyframes fadeinout {
  0%
   {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.pop-inner > .close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background: transparent;
  border: none;
  outline: none;
  color: #fff;
  font-size: 30px;
  border-radius: 50%;
  margin: 10px;
}
