@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;500&family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}
:root {
  --primary-color: #047484;
  --secondary-color: #252525;
  --third-color: #fff;
}
.needToKnow {
  padding: 120px 0;
  position: relative;
}
.know_img {
  width: 500px;
  height: 500px;
  overflow: hidden;
  position: relative;
  right: -20%;
}
.page_head_title {
  font-size: 50px;
  margin: 30px 0 40px 0;
  user-select: none;
  text-align: left;
}
.know_img > img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: cover;
}
.practice li {
  list-style: none;
  text-decoration: none;
}
.colors {
  color: #047484 !important;
}
.know_cont {
  width: 600px;
  height: auto;
  padding: 20px 30px;
  position: relative;
  background-color: #fff;
  left: -20%;
  top: 10%;
  box-shadow: 0 5px 20px rgba(105, 105, 105, 0.323);
}
.sub_head {
  color: #047484;
  font-size: 15px;
  font-weight: 500;
}
.know_cont > h1 {
  font-size: 32px;
  font-weight: 600;
  color: var(--secondary-color);
  line-height: 30px;
}
.know_para {
  padding: 20px 0;
  font-size: 15px;
  line-height: 1.5rem;
}
.sub_head2 {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  color: #515e6f;
  font-size: 22px;
  line-height: 1em;
}
.know_btn {
  display: inline-block;
  position: absolute;
  padding: 15px 20px;
  border-radius: 5px;
  background-color: #252525;
  color: #fff;
  text-decoration: none;
  margin-top: 5.5rem;
  font-size: 15px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  overflow: hidden;
  margin: 10px;
  z-index: 1;
  border: none;
}
.know_btn::after,
.know_btn::before {
  position: absolute;
  content: "";
  -webkit-transition: all 0.35s ease-in-out 0s;
  -moz-transition: all 0.35s ease-in-out 0s;
  -ms-transition: all 0.35s ease-in-out 0s;
  -o-transition: all 0.35s ease-in-out 0s;
  transition: all 0.35s ease-in-out 0s;
}
.know_btn::before {
  bottom: 0;
  left: 0;
  width: 200px;
  height: 200px;
  z-index: -1;
  background-color: var(--primary-color);
  -webkit-transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -4em, 0);
  transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -4em, 0);
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  -webkit-transition: all 0.35s ease-in-out 0s;
  -moz-transition: all 0.35s ease-in-out 0s;
  -ms-transition: all 0.35s ease-in-out 0s;
  -o-transition: all 0.35s ease-in-out 0s;
  transition: all 0.35s ease-in-out 0s;
}

.know_btn:hover::before {
  opacity: 1;
  -webkit-transform: rotate3d(0, 0, 1, 0deg);
  transform: rotate3d(0, 0, 1, 0deg);
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.know_btn:hover {
  color: #252525;
  font-weight: 600;
}
.sec_head {
  padding: 90px 0;
}
.sec_head_h1 {
  font-size: 30px;
  text-align: center;
  font-weight: 400;
  color: var(--secondary-color);
  font-family: "Raleway", sans-serif;
  user-select: none;
}
.who_we_are {
  padding: 70px 0;
}
.bloc-tabs {
  display: flex;
}
.tabs {
  padding: 15px;
  text-align: center;
  width: 50%;
  background: #fff;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  box-sizing: content-box;
  position: relative;
  outline: none;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--secondary-color);
  transition: all 0.2s ease-in-out;
}
/* .tabs:not(:last-child){
  border-right: 1px solid #eee;
} */
.tabs:hover {
  color: #252525;
}
.tabs.active-tab {
  background-color: #eee;
}
.active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 5px;
  background: rgb(88, 147, 241);
}
button {
  border: none;
}
.content-tabs {
  flex-grow: 1;
}
.content {
  background: white;
  padding: 20px;
  width: 100%;
  height: 100%;
  display: none;
}
.content h2 {
  padding: 0px 0 5px 0px;
}
.content hr {
  width: 100px;
  height: 2px;
  background: #222;
  margin-bottom: 5px;
}
.content p {
  width: 100%;
  height: 100%;
}
.active-content {
  display: block;
}
.tabss_cont > h2 {
  font-size: 30px;
  font-weight: 600;
  color: #252525;
}
.tabss_cont > p {
  font-size: 15px;
  line-height: 1.5rem;
}
.tab_img {
  width: 100%;
  height: 300px;
  position: relative;
}
.tab_img > img {
  max-width: 300px;
  padding: 10px;

  height: auto;
  width: auto;
}
.discover {
  padding: 50px 0;
  height: 100vh;
  position: relative;
  background: #f7f7f7;
}
.discover_area {
  width: 100%;
  height: 300px;
  background-color: transparent;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
.discover_img {
  width: 300px;
  height: auto;
  border-radius: 10px;
  overflow: hidden;
  float: right;
}
.discover_img > img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
}
.discover_cont > h1 {
  font-size: 60px;
  font-weight: 600;
  color: var(--secondary-color);
  user-select: none;
}
.ripple {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: relative;
  -webkit-transition: height 0.25s ease, width 0.25s ease;
  transition: height 0.25s ease, width 0.25s ease;

  cursor: pointer;
}
.ripple > img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
}

.ripple:before,
.ripple:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  border: 1px solid #32bea6;
}

.ripple:before {
  -webkit-animation: ripple 2s linear infinite;
  animation: ripple 2s linear infinite;
}
.ripple:after {
  -webkit-animation: ripple 2s linear 1s infinite;
  animation: ripple 2s linear 1s infinite;
}

.ripple:hover:before,
.ripple:hover:after {
  -webkit-animation: none;
  animation: none;
}

@-webkit-keyframes ripple {
  0% {
    -webkit-transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.75);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
    opacity: 0;
  }
}

@keyframes ripple {
  0% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.75);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
#term__ {
  width: 500px;
  padding: 0 20px;
}
.faq {
  padding: 70px 0;
  background: #f7f7f7;
}
.faq_cont {
  text-align: center;
  padding: 0 7em;
}
.faq_cont > p {
  font-weight: 600;
  color: #047484;
}
.faq_cont > h1 {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  color: #192836;
}
.accord_area {
  padding: 50px 0;
}
.accord {
  margin: 10px;
  box-shadow: none;
  border: none;
  font-size: 22px;
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: none !important;
  border: none !important;
}
.accord_title {
  font-size: 18px;
  color: var(--secondary-color);
  padding: 10px;
}
.accord_p {
  font-size: 15px;
  text-align: left;
}
.accord_sub_h {
  font-family: "Raleway", sans-serif;
  text-align: left;
  color: var(--third-color);
  padding: 10px;
  font-size: 26px;
}
.css-yw020d-MuiAccordionSummary-expandIconWrapper {
  font-size: 20px !important;
  padding: 10px;
  border-radius: 50%;
  background-color: var(--primary-color);
}
.trial2_cont {
  width: 100%;
  height: 550px;
  background-color: var(--primary-color);
  overflow: hidden;
  position: relative;
}
.abt_bgg {
  max-width: 100%;
  position: absolute;
  opacity: 0.2;
  left: 50%;
  transform: translateX(-50%);
}
.trial2_container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.trial2_container > h1 {
  text-align: center;
  font-weight: 700;
  color: var(--secondary-color);
  font-size: 46px;
}

.trial_btn {
  display: inline-block;
  position: absolute;
  padding: 5px;
  border-radius: 5px;
  background-color: #252525;
  color: #fff;
  text-decoration: none;
  margin-top: 5.5rem;
  width: 211px;
  font-size: 15px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  overflow: hidden;
  margin: 10px;
  z-index: 1;
  border: none;
  text-transform: uppercase;
  left: 50%;
  transform: translateX(-50%);
}
.trial_btn::after,
.trial_btn::before {
  position: absolute;
  content: "";
  -webkit-transition: all 0.35s ease-in-out 0s;
  -moz-transition: all 0.35s ease-in-out 0s;
  -ms-transition: all 0.35s ease-in-out 0s;
  -o-transition: all 0.35s ease-in-out 0s;
  transition: all 0.35s ease-in-out 0s;
}
.trial_btn::before {
  top: 0;
  left: 0;
  width: 150%;
  height: 100%;
  z-index: -1;
  background-color: var(--third-color);
  -webkit-transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -4em, 0);
  transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -4em, 0);
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  -webkit-transition: all 0.35s ease-in-out 0s;
  -moz-transition: all 0.35s ease-in-out 0s;
  -ms-transition: all 0.35s ease-in-out 0s;
  -o-transition: all 0.35s ease-in-out 0s;
  transition: all 0.35s ease-in-out 0s;
}
.trial_btn:hover::before {
  opacity: 1;
  -webkit-transform: rotate3d(0, 0, 1, 0deg);
  transform: rotate3d(0, 0, 1, 0deg);
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.trial_btn:hover {
  color: #192836;
  font-weight: 500;
}

@media screen and (max-width: 1200px) {
  .ripple {
    width: 100px;
    height: 90px;
  }
}
@media screen and (max-width: 1120px) {
  .ripple {
    width: 100px;
    height: 80px;
  }
}

@media screen and (max-width: 991px) {
  .know_cont {
    width: 400px;
  }
  .ripple {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: relative;
    -webkit-transition: height 0.5s ease, width 0.5s ease;
    transition: height 0.5s ease, width 0.5s ease;
    cursor: pointer;
  }
  .ripple:before,
  .ripple:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #32bea6;
  }
  .discover_area {
    width: 100%;
    height: 300px;
    background-color: transparent;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 62%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }
  html:not(.no-js) [data-aos^="zoom"][data-aos^="zoom"].aos-animate h1 {
    font-size: 10px;
  }
}
@media screen and (max-width: 786px) {
  .para-philo {
    text-align: justify;
    color: white !important;
    font-size: 9px;
  }
  .sub_head {
    text-align: center;
  }
  .page_head_title {
    text-align: center;
  }
  .know_img > img {
    max-width: 70%;
    max-height: 70%;
  }
  .know_img {
    display: none;
  }
  .ripple {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: relative;
    -webkit-transition: height 0.5s ease, width 0.5s ease;
    transition: height 0.5s ease, width 0.5s ease;
    cursor: pointer;
  }

  .ripple:before,
  .ripple:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #32bea6;
  }

  .ripple:before {
    -webkit-animation: ripple 2s linear infinite;
    animation: ripple 2s linear infinite;
  }
  .ripple:after {
    -webkit-animation: ripple 2s linear 1s infinite;
    animation: ripple 2s linear 1s infinite;
  }

  .ripple:hover:before,
  .ripple:hover:after {
    -webkit-animation: none;
    animation: none;
  }
  .drip-img {
    display: none !important;
  }
  .know_cont {
    width: 520px;

    margin-left: 20%;
  }
  .details_box > span {
    text-align: left;
    font-size: 13px;
    margin: 13px;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 95%;
  }
}

@media screen and (max-width: 500px) {
  .ripple {
    width: 50px;
    height: 50px;
  }
  .know_btn {
    position: relative;
  }
  .ripple > img {
    max-width: 118%;
    max-height: 130%;
    text-align: center;
    height: auto;
    width: auto;
  }
  .discover_img {
    width: 400px;
    height: auto;
    border-radius: 10px;
    overflow: hidden;
    right: 10%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 90%;
  }
  .know_cont {
    width: 100%;
    height: auto;
    margin-left: 20%;
  }
  .email {
    font-size: 10px !important;
  }
  .know_cont > h1 {
    line-height: 30px;
  }
  .trial2_cont {
    width: 100%;
    height: 621px;
    background-color: var(--primary-color);
    overflow: hidden;
    position: relative;
  }
  .ripple:before,
  .ripple:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 31px;
    height: 31px;
    border-radius: 50%;
    border: 1px solid #32bea6;
  }
  html:not(.no-js) [data-aos^="zoom"][data-aos^="zoom"].aos-animate {
    opacity: 1;
    transform: translateZ(0) scale(1);
    font-size: 23px;
    text-align: center;
  }
  .trial2_container > h1 {
    font-size: 50px;
  }
  .accord_title {
    font-size: 10px;
    color: var(--secondary-color);
    padding: 10px;
  }
  .accord_sub_h {
    font-family: "Raleway", sans-serif;
    text-align: left;
    color: var(--third-color);
    padding: 10px;
    font-size: 15px;
  }
  .accord {
    margin: 0px auto !important;
    box-shadow: none;
    border: none;
    font-size: 22px;
    width: 300px;
  }
  .faq_cont {
    text-align: center;
    padding: 0 2em;
  }
  .css-o4b71y-MuiAccordionSummary-content {
    width: 100%;
  }
  .discover {
    padding: 50px 0;
    height: 50vh;
    position: relative;
    background: #f7f7f7;
  }
  .tabss_cont {
    font-size: 14px;
  }
}
.read-more {
  font-size: 12px !important;
}
