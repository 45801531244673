.error_msg {
  font-size: 12px;
  color: #b00020;
}
.otp-area {
  position: relative;
  height: 100px;
  margin-top: 40px;
}
.otp-area div > input {
  width: 50px;
  height: 50px;
  border-radius: 3px;
  outline: none;
  border: 1px solid #9a9b82;
  transition: all 0.2s ease-in-out;
}
.otp-area div > input:focus {
  border-color: #c5d5bd;
}
.otp-area > div {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.otp-sub {
  font-size: 12px;
}
.verify {
  font-weight: 500;
}
.resend-otp {
  margin-top: 30px;
  text-align: center;
}
.resend-otp > span {
  cursor: pointer;
  font-weight: 500;
  color: #9a9b82;
}
