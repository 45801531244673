/* bottom area section */
.btm_bar {
  margin-top: 50px;
  padding: 50px 0;
}
.btm_details {
  padding: 30px;
  background-color: var(--primary-color);
}
.details_box {
  padding: 20px;
  width: 380px;
  overflow: hidden;
  position: relative;
}
.ico_c {
  width: 70px;
  height: 70px;
  border: 2px solid var(--primary-color);
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  text-align: center;
  left: 10px;
  top: 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.ico_c:hover {
  border-color: #d7e8d8;
}
.ico_c > .ico_c2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  padding: 10px 15px;
  background-color: #d7e8d8;
  border-radius: 50%;
  color: #545453;
}
.details_box > span {
  width: 200px;
  font-size: 25px;
  padding: 18px 10px;
  font-weight: 800;
  line-height: 1.5rem;
  color: #545453;
  user-select: none;
  position: absolute;
  left: 30%;
  text-align: left;
  transition: all 0.5s ease-in-out;
}
@media screen and (max-width:1200px) {
  .details_box > span{
    width: 170px;
    font-size: 20px;
    padding: 20px 7px;
  }
}
@media screen and (max-width:991px) {
  .details_box{
    padding: 0;
    width: auto;
    font-size: 18px;
    overflow: visible;
  }
  .details_box > span{
    width: 150px;
    font-size: 15px;
    padding: 20px;
  }
}
@media screen and (max-width: 786px) {
  .btm_details {
    padding: 40px;
  }
  .details_box > span {
    text-align: left;
    font-size: 13px;
  }
}
