.error-head {
  text-align: center;
  margin-top: 30px;
  font-size: 45px;
  font-weight: 600;
}
.lottie-area {
  width: 40%;
  margin: 0px auto;
  height: 50%;
  margin-bottom: 20px !important;
  margin-top: 5% !important;
}
.error-btn {
  display: inline-block;
  position: absolute;
  width: 200px;
  height: 60px;
  padding: 15px;
  border-radius: 10px;
  background-color: #252525;
  color: #fff;
  text-decoration: none;
  margin-top: 2rem;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  z-index: 1;
  left: 45%;
  transform: translateX(-50%) !important;
  overflow: hidden;
}
.error-btn::after,
.error-btn::before {
  position: absolute;
  content: "";
  -webkit-transition: all 0.35s ease-in-out 0s;
  -moz-transition: all 0.35s ease-in-out 0s;
  -ms-transition: all 0.35s ease-in-out 0s;
  -o-transition: all 0.35s ease-in-out 0s;
  transition: all 0.35s ease-in-out 0s;
}
.error-btn::before {
  top: 0;
  left: 0;
  width: 150%;
  height: 100%;
  z-index: -1;
  background-color: var(--primary-color);
  -webkit-transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -4em, 0);
  transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -4em, 0);
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  -webkit-transition: all 0.35s ease-in-out 0s;
  -moz-transition: all 0.35s ease-in-out 0s;
  -ms-transition: all 0.35s ease-in-out 0s;
  -o-transition: all 0.35s ease-in-out 0s;
  transition: all 0.35s ease-in-out 0s;
}

.error-btn:hover::before {
  opacity: 1;
  -webkit-transform: rotate3d(0, 0, 1, 0deg);
  transform: rotate3d(0, 0, 1, 0deg);
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.error-btn:hover {
  color: #252525;
}
.ero_msg {
  font-size: 20px;
  margin-top: 15% !important;
  width: 60%;
  text-align: center;
  margin: 0px auto !important;
}
.backHome {
  position: absolute;
  width: 200px;
  height: 60px;
  padding: 15px;
  border-radius: 10px;
  border: 2px solid #252525;
  color: #252525;
  text-decoration: none;
  margin-top: 2rem;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  z-index: 1;
  left: 60%;
  transform: translateX(-50%) !important;
  overflow: hidden;
  background-color: transparent;
}

