@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;500&family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap");
:root {
  --primary-color: #047484;
  --secondary-color: #252525;
  --third-color: #fff;
}
.pro_banner {
  padding: 70px 0;
}
.proBanner {
  position: relative;
  width: 100%;
  height: 500px;
  background-color: var(--primary-color);
  overflow: hidden;
}
.pb_cont {
  width: 700px;
  height: 100px;
  position: absolute;
  bottom: 50%;
}
.pro_title {
  font-family: "Poppins", sans-serif;
  font-size: 60px;
  color: var(--secondary-color);
  font-weight: 900;
}
.pro_sub {
  font-size: 20px;
  color: var(--secondary-color);
  font-weight: 500;
}
.proBanner_sub_cont {
  position: absolute;
  left: 5rem;
  bottom: 20px;
  z-index: 1;
}
.proBanner_sub_cont > h2 {
  font-size: 20px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: var(--secondary-color);
  user-select: none;
}

.banner_shape1 {
  position: absolute;
  left: 0;
  bottom: -10%;
}
.proBanner::before {
  content: "";
  border-top: 76px solid transparent;
  border-right: 1920px solid rgba(255, 255, 255, 0.54);
  position: absolute;
  bottom: 0;
  z-index: 10;
}
.program {
  position: relative;
}
.program_wrapper {
  padding: 30px 0;
}
.cover {
  max-height: 900px;
  overflow: hidden;
  transition: height 0.2s linear;
}
.cover.hide {
  max-height: 100%;
}
.program_container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  overflow: hidden;
}
.pro_items {
  border-radius: 10px;
  box-shadow: 0 2px 3px #747474;
  margin: 10px;
  position: relative;
  transition: all 0.5s linear;
  cursor: pointer;
  overflow: hidden;
  background-color: #eee;
}
.pro_items img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
}
.pros::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.61);
  opacity: 0;
  transition: all 0.5s ease-in-out;
}
.pro_items > p {
  color: #fff;
  font-family: "Poppins", sans-serif;
  position: absolute;
  z-index: 2;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 28px;
  text-align: center;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  line-height: 2rem;
  font-weight: 600;
}
.pro_items:hover > p {
  opacity: 1;
}
.pro_items:hover::before {
  opacity: 1;
}
.filter_item ul {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  gap: 1em;
  margin-bottom: 30px;
  overflow: hidden;
  overflow-x: scroll;
}
.filter_item ul::-webkit-scrollbar {
  width: 0.5em;
}

.filter_item ul::-webkit-scrollbar-track {
  box-shadow: 0 0 0 transparent;
}

.filter_item ul::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.pro_btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  transition: all 0.2s linear;
  font-weight: 500;
  font-size: 15px;
}
.pro_btn:hover {
  background-color: var(--secondary-color);
  color: #fff;
}
.pro_btn.active {
  background: var(--primary-color);
  color: #fff !important;
}
.show_more {
  width: 100%;
  padding: 20px 0;
  min-height: 200px;
}
.show_more > button {
  position: absolute;
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  left: 50%;
  transform: translateX(-50%);
}
.show_more > button:hover {
  color: #252525;
}
.filter_item {
  position: relative;
}
.filter_nav {
  position: absolute;
  padding: 8px 12px;
  border-radius: 50%;
}
.filter_item > .filter_nav.right {
  right: 0;
  top: 0;
}
.free_month {
  padding: 70px 0;
}
.free_month_cont > h1 {
  font-size: 60px;
  font-weight: 400;
  color: #192836;
  font-family: "Poppins", sans-serif;
}
.free_month_cont h1 > span {
  color: var(--third-color);
  font-weight: 600;
  font-style: italic;
}
.free_month_cont > p {
  font-size: 18px;
  color: #192836;
  font-weight: 500;
}
.free_month_btn {
  display: inline-block;
  position: absolute;
  padding: 15px 20px;
  border-radius: 10px;
  background-color: #252525;
  color: #fff;
  text-decoration: none;
  margin-top: 5.5rem;
  font-size: 15px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  overflow: hidden;
  margin: 10px;
  z-index: 1;
  border: none;
}
.free_month_btn::after,
.free_month_btn::before {
  position: absolute;
  content: "";
  -webkit-transition: all 0.35s ease-in-out 0s;
  -moz-transition: all 0.35s ease-in-out 0s;
  -ms-transition: all 0.35s ease-in-out 0s;
  -o-transition: all 0.35s ease-in-out 0s;
  transition: all 0.35s ease-in-out 0s;
}
.free_month_btn::before {
  top: 0;
  left: 0;
  width: 150%;
  height: 100%;
  z-index: -1;
  background-color: var(--primary-color);
  -webkit-transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -4em, 0);
  transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -4em, 0);
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  -webkit-transition: all 0.35s ease-in-out 0s;
  -moz-transition: all 0.35s ease-in-out 0s;
  -ms-transition: all 0.35s ease-in-out 0s;
  -o-transition: all 0.35s ease-in-out 0s;
  transition: all 0.35s ease-in-out 0s;
}
.free_month_btn:hover::before {
  opacity: 1;
  -webkit-transform: rotate3d(0, 0, 1, 0deg);
  transform: rotate3d(0, 0, 1, 0deg);
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.free_month_btn:hover {
  color: #192836;
  font-weight: 500;
}
.pro_items button {
  padding: 5px 10px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10%;
  z-index: 10;
  background-color: #19283655;
  color: #fff;
}

.modal_wrapper {
  position: fixed;
  width: 100%;
  min-height: 100%;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: saturate(100%) blur(2px);
  z-index: 100;
}

.modal_wrapper > .modal_container {
  position: absolute;
  width: 800px;
  height: 700px;
  background-color: #eee;
  padding: 20px 30px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  -webkit-animation: fadein 2s;
  -moz-animation: fadein 2s;
  -ms-animation: fadein 2s;
  -o-animation: fadein 2s;
  animation: fadein 2s;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.modal_wrapper .modal_container > .modal_img {
  min-width: 100%;
  min-height: 200px;
  position: relative;
  overflow: none;
}
.modal_img > img {
  max-width: 100%;
}
.modal_cont > h1 {
  font-size: 30px;
  font-weight: 600;
  color: var(--secondary-color);
}
.modal_cont > p {
  font-size: 15px;
  color: var(--secondary-color);
}
.price {
  color: #e5ad44;
  font-weight: 600;
  font-size: 30px;
}
.price > span {
  font-size: 20px;
  color: var(--third-color);
}
.modal_btn {
  position: absolute;
  bottom: 10%;
}
.modal_btn > button {
  padding: 10px 30px;
  margin: 0.4em;
  box-shadow: 0 2px 3px #7777;
}
.modal_btn > .buy {
  background-color: #192836;
  color: var(--third-color);
  border-radius: 5px;
  transition: all 0.2s linear;
}
.modal_btn > .buy:hover {
  background-color: var(--third-color);
  color: #192836;
}
.modal_btn > .cart {
  color: #f3728b;
  border: 1px solid #f3728b;
  border-radius: 5px;
}
.modal_container > .close {
  float: right;
  padding: 1px 5px;
  border-radius: 50%;
  background-color: #c4d4bc56;
  color: #252525;
  cursor: pointer;
}

@media screen and (max-width: 786px) {
  .banner_shape1 {
    display: none;
  }
  .pro_sub {
    width: 500px;
    font-size: 15px;
  }
  .proBanner_sub_cont {
    left: 2rem;
  }
  .proBanner_sub_cont > h2 {
    font-size: 15px;
  }
  .program_container {
    display: block;
  }
  .cover {
    max-height: 100% !important;
  }
  .show_more {
    display: none;
  }
  .filter_item > ul {
    display: block;
  }
  .filter_item ul > li {
    margin: 10px;
  }
}
.ev_bgg {
  position: absolute;
  width: 500px;
  height: 100%;
  background-image: url("../../assets/bgg5.png");
  background-repeat: no-repeat;
  background-size: 100%;
  opacity: 0.2;
  right: 0;
  top: 30%;
  z-index: -1;
}

@media screen and (max-width: 500px) {
  .pb_cont {
    width: 400px;
  }
  .pb_cont > .pro_title {
    font-size: 35px;
  }
  .pro_sub {
    width: 300px;
    font-size: 15px;
  }
  .modal_container {
    flex-direction: column;
    top: 0;
    left: 0;
    transform: none;
    width: 100%;
    height: 100vh;
  }
}
