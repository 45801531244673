.forget_input {
  position: relative;
}
.word_eye {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
}
.password {
  z-index: -1;
}
.profile-image {
  padding: 20px;
}
.profile-image img {
  width: 100%;
}
.box-name {
  padding: 10px;
  background-color: #047484;
  color: white;
}
.name-data {
  padding: 0;
  margin: 0;
}
.eye-btn-sign {
  position: relative;
  left: 95%;
  top: -55px;
}

