.back2top {
  width: 50px;
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 99;
  font-size: 30px;
  background-color: #63a9b4;
  color: #ffffff;
  cursor: pointer;
  padding: 0 10px;
  animation: fadeIn 0.5s linear;
  border-radius: 5px;
  overflow: hidden;
}
@keyframes fadeIn {
  0% {
    height: 0;
  }
  100% {
    height: 50px;
  }
}

