.alert_modal_wrapper > .modal_container {
  position: fixed;
  width: 20vw;
  height: auto;
  background-color: #d5dfcf;
  border: 1px solid #eee;
  bottom: 50px;
  left: 50px;
  padding: 10px;
  z-index: 999;
  border-radius: 6px;
  box-shadow: 0 0 3px #989898;
  overflow: hidden;
  animation: animatebottom 1s;
}
.close_btn{
    background-color: transparent !important;
}
.modal_container.info{
    background-color: #fff;
}
.alert_modal_wrapper .modal_container h2 {
  font-size: 18px;
}
.alert_modal_wrapper .modal_container p {
  font-size: 13px;
}
@keyframes animatebottom {
  from {
    bottom: -300px;
    opacity: 0;
  }

  to {
    bottom: 50px;
    opacity: 1;
  }
}
