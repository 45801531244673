.forgt-card {
  width: 25%;
  margin: 0px auto;
  margin-top: 2%;
  font-size: 13px;
}
.para-pass {
  font-size: 12px;
  text-align: center;
  margin-bottom: 30px;
}
.break {
  margin-top: 30px;
  border-radius: 3px;
  font-size: 16px !important;
  font-weight: 500;
  width: 70%;
  height: 40px;
}
.close {
  width: 20% !important;
}
:where(.css-dev-only-do-not-override-1km3mtt).ant-modal .ant-modal-content {
  width: 80% !important;
  margin: 0px auto;
}
:where(.css-dev-only-do-not-override-1km3mtt).ant-btn-primary{
  background-color: #1677ff !important;
}
:where(.css-dev-only-do-not-override-1km3mtt).ant-btn-primary:disabled{
  color: white;
}
.log_content {
  width: 100%;
  height: auto;
  padding: 20px;
}
.log_content ol,
ul {
  padding: 0 !important;
}
.for-pass {
  text-align: center;
  margin-top: 20px;
  font-size: 25px;
}
.ali-logo {
  width: 200px !important;
  margin-top: 100px;
}
.list-sty {
  list-style: none;
  text-align: justify;
}
.forget_label {
  padding-top: 10px;
  font-weight: 500;
}
.pass_eye {
  position: absolute;
  margin-left: -29px;
  float: right;
  z-index: 999;
  top: 49%;
  transform: translateY(-50%);
}
.tips {
  position: absolute;
  margin: 30px 0;
  font-size: 13px;
  left: 50%;
  transform: translateX(-50%);
}
.tips > ul {
  list-style: none;
  display: flex;
}
.tips ul > li {
  margin: 0 10px;
  text-align: center;
}
.tips ul li > a {
  text-decoration: none;
  color: #747474;
  font-weight: 500;
  padding: 0 10px;
  transition: all 0.2s ease-in-out;
}
.tips ul li > a:hover {
  color: #252525;
}

.title {
  margin: auto;
  text-align: center;
  font-family: "Poppins", sans-serif;
}
.title h3 {
  font-weight: bold;
}
.title p {
  font-size: 12px;
  font-weight: 500;
}
.msg {
  color: initial;
  text-align: center;
  font-weight: bold;
}
.otp-input-fields {
  margin: auto;
  background-color: white;
  box-shadow: 0px 0px 4px 0px #02025044;
  max-width: 400px;
  width: auto;
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 40px;
}
.otp-form {
  margin-top: 20px !important;
}
.otp-box {
  padding: 20px;
  border: 1px solid rgb(243, 243, 243);
  border-radius: 4px;
  width: 500px;
  margin: 0px auto;
  margin-top: 60px;
  text-align: center;
}
.result {
  max-width: 400px;
  margin: auto;
  padding: 24px;
  text-align: center;
}
.result p {
  font-size: 24px;
  font-family: "Antonio", sans-serif;
  opacity: 1;
  transition: color 0.5s ease;
}
input:focus {
  outline: none;
}

.otp-filed {
  border: 1px solid #d8eaec !important;
  height: auto;
  width: 100px;
  border-radius: 5px;
  border: 1px #95a28f;
  text-align: center;
  font-family: arimo;
  font-size: 1.2rem;
  background: #d8eaec;
}
.field {
  background-color: #047484;
  color: white;
  font-weight: 500;
  padding: 5px;
  border-radius: 6px;
  margin-top: 50px;
}
.sub-field {
  text-align: center;
  margin: 0px auto;
}
@media screen and (max-width: 986px) {
  .forgt-card {
    width: 50%;
    margin: 0px auto;
    margin-top: 5%;
  }
}
@media screen and (max-width: 600px) {
  .card-pass-f {
    width: 100% !important;
  }
  .forgt-card {
    width: 100%;
    margin: 0px auto;
    margin-top: 20%;
  }
}
